<template>
    <v-container fluid>
      <v-toolbar color="accent" flat dark height="40">
        <v-toolbar-title>G/L Accounts Determination</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <!-- start of editor -->
      <editor :initial="record" @data="save" :inputTaxes="inputTaxes" :outputTaxes="outputTaxes"></editor>
      <!-- end of editor -->
    </v-container>
</template>

<script>
import Editor from "./_components/editor.vue";
export default {
  components: {
    Editor
  },
  data: () => ({
    search: "",
    record: {},
    inputTaxes: [],
    outputTaxes: []
  }),
  methods: {
    save(data) {
      const url = "/gl_account_determination";
      this.$store
        .dispatch("post", { url, data })
        .then(() => {
          location.reload();
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getInputTax() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/taxgroups/input`)
        .then(res => {
          self.inputTaxes = res;
          self.loading = false;
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getOutputTax() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/taxgroups/output`)
        .then(res => {
          self.outputTaxes = res;
          self.loading = false;
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getData() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/gl_account_determination`)
        .then(res => {
          self.record = res.ResponseData;
          self.loading = false;
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    }
  },
  created() {
    this.getInputTax();
    this.getOutputTax();
    this.getData();
  }
};
</script>