<template>
  <div>
    <v-card>
      <v-tabs>
        <v-tab dark>Sales</v-tab>
        <v-tab dark>Purchasing</v-tab>
        <v-tab dark>General</v-tab>
        <v-tab dark>Inventory</v-tab>
        <v-tab dark>Resources</v-tab>
        <!-- sales item -->
        <v-tab-item>
          <v-row class="pa-3">
            <v-col cols="12">
              <!-- inner tabs -->
              <v-tabs
                background-color="tabColor"
                color="white"
              >
                <v-tab>General</v-tab>
                <v-tab>Tax</v-tab>
                <v-tab-item>
                  <v-row class="pa-2">
                    <!-- sales accounts setup -->
                    <v-col cols="12">
                      <v-data-table
                        hide-default-footer
                        :headers="headers"
                        :items="saleAccounts"
                      >
                        <template v-slot:item.Code="{ item }">
                          <v-text-field
                            style="width: 200px"
                            single-line
                            outlined
                            dense
                            v-model="item.Code"
                            :append-icon="'mdi-airballoon'"
                            @click:append="openModal(item, 's')"
                          ></v-text-field>
                        </template>

                        <template v-slot:item.AcctName="{ item }">
                          <span>{{ item.AcctName }}</span>
                        </template>
                      </v-data-table>
                    </v-col>
                    <!-- end of accounts -->
                  </v-row>
                </v-tab-item>
                <v-tab-item>
                  <v-col
                    cols="3"
                    class="pa-2"
                  >
                    <v-row>
                      <v-autocomplete
                        v-model="record.DfSVatItem"
                        :items="outputTaxes"
                        item-text="name"
                        item-value="code"
                        label="Sales Tax Group(Items)"
                      ></v-autocomplete>
                    </v-row>

                    <v-row>
                      <v-autocomplete
                        v-model="record.DfSVatServ"
                        :items="outputTaxes"
                        item-text="name"
                        item-value="code"
                        label="Sales Tax Group(Service)"
                      ></v-autocomplete>
                    </v-row>
                  </v-col>
                </v-tab-item>
              </v-tabs>
              <!-- end of inner tabs -->
            </v-col>
          </v-row>
        </v-tab-item>
        <!-- end of sales item -->

        <!-- purchasing item -->
        <v-tab-item>
          <v-row class="pa-3">
            <v-col cols="12">
              <!-- inner tabs -->
              <v-tabs
                background-color="tabColor"
                color="white"
              >
                <v-tab>General</v-tab>
                <v-tab>Tax</v-tab>
                <v-tab-item>
                  <v-row class="pa-2">
                    <!-- purchases accounts setup -->
                    <v-col cols="12">
                      <v-data-table
                        hide-default-footer
                        :headers="headers"
                        :items="purchasingAccounts"
                      >
                        <template v-slot:item.Code="{ item }">
                          <v-text-field
                            style="width: 200px"
                            single-line
                            outlined
                            dense
                            v-model="item.Code"
                            :append-icon="'mdi-airballoon'"
                            @click:append="openModal(item, 'p')"
                          ></v-text-field>
                        </template>

                        <template v-slot:item.AcctName="{ item }">
                          <span>{{ item.AcctName }}</span>
                        </template>
                      </v-data-table>
                    </v-col>
                    <!-- end of accounts -->
                  </v-row>
                </v-tab-item>
                <v-tab-item>
                  <v-col
                    cols="3"
                    class="pa-2"
                  >
                    <v-row>
                      <v-autocomplete
                        v-model="record.DfPVatItem"
                        :items="inputTaxes"
                        item-text="name"
                        item-value="code"
                        label="Purchase Tax Group(Items)"
                      ></v-autocomplete>
                    </v-row>

                    <v-row>
                      <v-autocomplete
                        v-model="record.DfPVatServ"
                        :items="inputTaxes"
                        item-text="name"
                        item-value="code"
                        label="Purchase Tax Group(Service)"
                      ></v-autocomplete>
                    </v-row>
                  </v-col>
                </v-tab-item>
              </v-tabs>
              <!-- end of inner tabs -->
            </v-col>
          </v-row>
        </v-tab-item>
        <!-- end of purchasing tab -->

        <!-- general tab -->
        <v-tab-item>
          <v-row>
            <v-col cols="12">
              <v-data-table
                hide-default-footer
                :headers="headers"
                :items="generalAccounts"
              >
                <template v-slot:item.Code="{ item }">
                  <v-text-field
                    style="width: 200px"
                    single-line
                    outlined
                    dense
                    v-model="item.Code"
                    :append-icon="'mdi-airballoon'"
                    @click:append="openModal(item, 'g')"
                  ></v-text-field>
                </template>

                <template v-slot:item.AcctName="{ item }">
                  <span>{{ item.AcctName }}</span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-tab-item>
        <!-- end -->

        <!-- inventory tab -->
        <v-tab-item>
          <v-row>
            <v-col cols="12">
              <v-data-table
                hide-default-footer
                :headers="headers"
                :items="inventoryAccounts"
              >
                <template v-slot:item.Code="{ item }">
                  <v-text-field
                    style="width: 200px"
                    single-line
                    outlined
                    dense
                    v-model="item.Code"
                    :append-icon="'mdi-airballoon'"
                    @click:append="openModal(item, 'i')"
                  ></v-text-field>
                </template>

                <template v-slot:item.AcctName="{ item }">
                  <span>{{ item.AcctName }}</span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-tab-item>
        <!-- end -->

        <!-- resources tab -->
        <v-tab-item>
          <v-row>
            <v-col cols="12">
              <v-data-table
                hide-default-footer
                :headers="headers"
                :items="resourcesAccounts"
              >
                <template v-slot:item.Code="{ item }">
                  <v-text-field
                    style="width: 200px"
                    single-line
                    outlined
                    dense
                    v-model="item.Code"
                    :append-icon="'mdi-airballoon'"
                    @click:append="openModal(item, 'r')"
                  ></v-text-field>
                </template>

                <template v-slot:item.AcctName="{ item }">
                  <span>{{ item.AcctName }}</span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-tab-item>
        <!-- end -->
      </v-tabs>

      <v-row>
        <v-dialog
          v-model="dialog"
          max-width="800px"
        >
          <v-card>
            <v-toolbar
              color="accent"
              dense
              dark
            >
              <v-toolbar-title>List of Accounts</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                @click.native="dialog = false"
                icon
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-card-text>
              <v-row>
                <v-data-table
                  hide-default-footer
                  :headers="glHeaders"
                  :items="glAccounts"
                  show-select
                  :single-select="singleSelect"
                  v-model="selected"
                  @item-selected="clickedItem"
                >
                </v-data-table>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>

      <v-row class="px-3">
        <v-col cols="12">
          <v-btn
            color="accent"
            @click="sendData"
            :loading="loader"
          >
            <v-icon left>mdi-content-save</v-icon>Save
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>


<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true,
    },
    inputTaxes: {
      type: Array,
    },
    outputTaxes: {
      type: Array,
    },
  },
  data: () => ({
    record: {},
    loader: false,
    category: null,
    saleAccounts: [],
    purchasingAccounts: [],
    generalAccounts: [],
    inventoryAccounts: [],
    resourcesAccounts: [],
    singleSelect: true,
    selected: [],
    dialog: false,
    glAccounts: [],
    selectedId: null,
    taxGroups: [],
    headers: [
      { text: "Type of Account", value: "Description" },
      { text: "Account Code", value: "Code" },
      { text: "Account Name", value: "AcctName" },
    ],
    glHeaders: [
      { text: "Account Number", value: "AcctCode" },
      { text: "Account Name", value: "AcctName" },
      { text: "Balance", value: "CurrTotal" },
    ],
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      this.record = { ...val };
    },
    setDate(date) {
      this.record.date = date;
    },
    openModal(item, category) {
      this.dialog = true;
      this.category = category;
      this.selectedId = item.id;
    },
    sendData() {
      const acp10 = [
        ...this.saleAccounts,
        ...this.purchasingAccounts,
        ...this.generalAccounts,
        ...this.inventoryAccounts,
        ...this.resourcesAccounts,
      ];
      const details = {
        CostPrcLst: null,
        DfltWhs: null,
        GLMethod: null,
        DfSVatItem: this.record.DfSVatItem || null,
        DfSVatServ: this.record.DfSVatServ || null,
        DfPVatItem: this.record.DfPVatItem || null,
        DfPVatServ: this.record.DfPVatServ || null,
        acp10: acp10,
      };
      this.loader = true;
      this.$emit("data", details);
    },
    clickedItem($event) {
      const account = $event.item;
      const id = this.selectedId;
      let activeRecord = {};
      let pos = null;
      let category = this.category;
      // check sale category
      if (category == "s") {
        activeRecord = this.saleAccounts.find((record) => {
          return record.id === id;
        });

        pos = this.saleAccounts.indexOf(activeRecord);
        if (pos === -1) {
          return;
        }

        activeRecord.Code = account.AcctCode;
        activeRecord.AcctCode = account.id;
        activeRecord.AcctName = account.AcctName;
        this.saleAccounts[pos] = activeRecord;
      }
      // end

      // check purchasing category
      if (category == "p") {
        activeRecord = this.purchasingAccounts.find((record) => {
          return record.id === id;
        });

        pos = this.purchasingAccounts.indexOf(activeRecord);
        if (pos === -1) {
          return;
        }

        activeRecord.Code = account.AcctCode;
        activeRecord.AcctCode = account.id;
        activeRecord.AcctName = account.AcctName;
        this.purchasingAccounts[pos] = activeRecord;
      }
      // end

      // check purchasing category
      if (category == "g") {
        activeRecord = this.generalAccounts.find((record) => {
          return record.id === id;
        });

        pos = this.generalAccounts.indexOf(activeRecord);
        if (pos === -1) {
          return;
        }

        activeRecord.Code = account.AcctCode;
        activeRecord.AcctCode = account.id;
        activeRecord.AcctName = account.AcctName;
        this.generalAccounts[pos] = activeRecord;
      }
      // end

      // check inventory category
      if (category == "i") {
        activeRecord = this.inventoryAccounts.find((record) => {
          return record.id === id;
        });

        pos = this.inventoryAccounts.indexOf(activeRecord);
        if (pos === -1) {
          return;
        }

        activeRecord.Code = account.AcctCode;
        activeRecord.AcctCode = account.id;
        activeRecord.AcctName = account.AcctName;
        this.inventoryAccounts[pos] = activeRecord;
      }
      // end

      // check resources category
      if (category == "r") {
        activeRecord = this.resourcesAccounts.find((record) => {
          return record.id === id;
        });

        pos = this.resourcesAccounts.indexOf(activeRecord);
        if (pos === -1) {
          return;
        }

        activeRecord.Code = account.AcctCode;
        activeRecord.AcctCode = account.id;
        activeRecord.AcctName = account.AcctName;
        this.resourcesAccounts[pos] = activeRecord;
      }
      // end

      this.category = null;
      this.selectedId = null;
      this.selected = [];
      this.dialog = false;
    },
    getSaleAccounts() {
      const self = this;
      this.$store
        .dispatch("get", `/gl_account-determination-category/s`)
        .then((res) => {
          self.saleAccounts = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getPurchasingAccounts() {
      const self = this;
      this.$store
        .dispatch("get", `/gl_account-determination-category/p`)
        .then((res) => {
          self.purchasingAccounts = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getGeneralAccounts() {
      const self = this;
      this.$store
        .dispatch("get", `/gl_account-determination-category/g`)
        .then((res) => {
          self.generalAccounts = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getInventoryAccounts() {
      const self = this;
      this.$store
        .dispatch("get", `/gl_account-determination-category/i`)
        .then((res) => {
          self.inventoryAccounts = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getResourcesAccounts() {
      const self = this;
      this.$store
        .dispatch("get", `/gl_account-determination-category/r`)
        .then((res) => {
          self.resourcesAccounts = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getActiveGLAccounts() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/activeGLaccounts`)
        .then((res) => {
          self.glAccounts = res;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getSaleAccounts();
    this.getPurchasingAccounts();
    this.getGeneralAccounts();
    this.getInventoryAccounts();
    this.getResourcesAccounts();
    this.getActiveGLAccounts();
  },
};
</script>